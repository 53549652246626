import "../css/DwptOperation.css";
import "@aws-amplify/ui-react/styles.css";

import { Button } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  getApiSetData,
  getErrMsg,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
import { connectStr } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// DWPT(路) 制御内容 ラベルを渡す
const getOperationModeLabel = (str: string) => {
  const operation_mode_label_map: keyTypeString = {
    charge: "充電",
    discharge: "放電",
    stop: "停止",
  };

  return !operation_mode_label_map[str]
    ? "－"
    : `${operation_mode_label_map[str]}(`;
};

// DWPT(路) 制御内容 一覧表表示データ テンプレート
const createData = (
  name: string,
  controlled_at: string,
  operation_mode: string,
  operation_value: string,
  resource_id: string
) => {
  return { name, controlled_at, operation_mode, operation_value, resource_id };
};

// DWPT(路) 制御内容 1件のレスポンスデータの型
type getData = {
  name: string; //必須
  device_id: string; //必須
  resource_id: string; //必須
  controlled_at: string;
  operation_mode: string;
  operation_value: number;
};

/**
 * 画面の描画
 */
const DwptOperation: React.FC = () => {
  const [dwptOperationData, setDwptOperationData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/dwpt_road/current_operation`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const rowsList: Array<keyTypeString> = [];
        response.forEach((resData: getData) => {
          const controlled_at = !resData.controlled_at
            ? "－"
            : moment(resData.controlled_at).format("YYYY/MM/DD HH:mm");

          const operation_mode = getOperationModeLabel(resData.operation_mode);

          //非数なら"0.0"を表示
          const operation_value =
            typeof resData.operation_value === "number"
              ? roundTwoDecimal(Math.abs(resData.operation_value)).toFixed(1)
              : "0.0";

          rowsList.push(
            createData(
              resData.name,
              controlled_at == "Invalid date" ? "－" : controlled_at,
              operation_mode,
              operation_mode == "－" ? "" : `${operation_value}kW)`,
              resData.resource_id
            )
          );
        });

        setDwptOperationData(() => {
          if (rowsList.length == 0) {
            return <span>表示するデータがありません</span>;
          } else {
            return (
              <TableContainer
                component={Paper}
                className="tableContents dwptOperationContents"
              >
                <Table aria-label="simple table" className="dwptOperationTable">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>制御時刻</TableCell>
                      <TableCell>制御内容</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row) => {
                      return (
                        <TableRow key={row.resource_id}>
                          <TableCell scope="row" title={row.name}>
                            {row.name}
                          </TableCell>
                          <TableCell>{row.controlled_at}</TableCell>
                          <TableCell>
                            <Box component="div">{row.operation_mode}</Box>
                            <Box component="div">{row.operation_value}</Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        });
      })
      .catch((err) => {
        setDwptOperationData(getErrMsg(err));
      });
  };

  useEffect(() => {
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="dwpt_1">
        <Box component="div" className="tableTitle">
          制御内容
        </Box>
        <Box component="div">{dwptOperationData}</Box>
        <Button className="hiddenBtn updateBtn" onClick={updateRows}></Button>
      </Box>
    </>
  );
};

export default DwptOperation;
