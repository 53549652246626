import "../css/Re100Timely.css";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import EventIcon from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import ja from "date-fns/locale/ja";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  getApiSetData,
  getErrMsg,
  getYaxisDomainMax,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
//RE100 5分
import { connectStr, selectTimeList, timeList } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// DatePickerを日本語に設定
registerLocale("ja", ja);

// RE100需給計画・実績(5分単位) 一覧表初期表示データ テンプレート
const createInitData = (
  calculated_at: string,
  supply: string,
  demand: string,
  delta_p: string,
  delta_last15_p: string,
  average_of_last15_c: string,
  coefficient_m: string,
  control_value: string
) => {
  return {
    calculated_at,
    supply,
    demand,
    delta_p,
    delta_last15_p,
    average_of_last15_c,
    coefficient_m,
    control_value,
  };
};

//セレクトボックスの初期選択
const initialTime = () => {
  const now = new Date();
  const hour = `0${now.getHours()}`.slice(-2);
  const min = now.getMinutes();
  const setTime = 30 < min ? `${hour}:30` : `${hour}:00`;
  const setTimeRows = timeList.filter((num) => {
    return num.start_time === setTime;
  });

  const initialData = `${setTimeRows[0].items[0]} ‐ ${setTimeRows[0].items[5]}`;
  return initialData;
};

// RE100需給計画・実績(5分単位) 一覧表の初期表示データ
const createRows = () => {
  const elmRe100TimelySelectbox = document.getElementById(
    "re100TimelySelectbox"
  ) as HTMLSelectElement;
  const hmList = elmRe100TimelySelectbox.value.split(" ‐ "); //"18:00 ‐ 18:25"

  const setTimeRows = timeList.filter((num) => {
    return num.start_time === hmList[0];
  });

  const initialData = setTimeRows[0].items.map((time) => {
    return createInitData(time, "－", "－", "－", "－", "－", "－", "－");
  });

  return initialData;
};

/**
 * 画面の描画
 */
const Re100Timely: React.FC = () => {
  const [targetTime, setTargetTime] = useState({
    start: moment(),
    end: moment(),
  });
  const handleOutputStartDate = (date: Date) => {
    setTargetTime((data) => ({
      ...data,
      start: moment(date),
    }));
  };

  //RE100需給計画・実績(5分単位) セレクトボックス
  const [selectTime, setSelectTime] = useState("");

  //RE100需給計画・実績(5分単位) セレクトボックスの日付変更時のイベント
  const handleChange = (event: SelectChangeEvent) => {
    setSelectTime(event.target.value);
  };

  const [re100TimelyData, setRe100TimelyData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    //日付テキストボックス
    const elmRe100Timely = document.getElementById(
      "re100TimelyPicker"
    ) as HTMLButtonElement;
    const YMD = !elmRe100Timely.value
      ? "－"
      : moment(elmRe100Timely.value).format("YYYYMMDD");

    const elmRe100TimelySelectbox = document.getElementById(
      "re100TimelySelectbox"
    ) as HTMLSelectElement;
    const hmList = elmRe100TimelySelectbox.value.replace(/:/g, "").split(" ‐ "); //"1800 ‐ 1825"

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/re100/calculation/timely_reports?selected_from=${YMD}${hmList[0]}&selected_to=${YMD}${hmList[1]}`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const rowsList = createRows();
        const numList: Array<number> = [];
        response.forEach((resData: any) => {
          const resConvCalculated_at = !resData.calculated_at
            ? "－"
            : moment(resData.calculated_at).format("YYYYMMDD HH:mm");
          const num = parseInt(resConvCalculated_at.slice(-2), 10) / 5;
          const resNum = 6 <= num ? num - 6 : num;

          const rowDate = rowsList[resNum];
          const ymdCalculated_at = `${YMD} ${rowDate.calculated_at}`;

          if (ymdCalculated_at == resConvCalculated_at) {
            rowDate.calculated_at = resConvCalculated_at.slice(-5);
            //非数なら"－"を表示
            rowDate.supply =
              typeof resData.supply === "number"
                ? `${roundTwoDecimal(resData.supply).toFixed(1)}`
                : "－";
            rowDate.demand =
              typeof resData.demand === "number"
                ? `${roundTwoDecimal(resData.demand).toFixed(1)}`
                : "－";
            rowDate.delta_p =
              typeof resData.delta_p === "number"
                ? `${roundTwoDecimal(resData.delta_p).toFixed(1)}`
                : "－";
            rowDate.delta_last15_p =
              typeof resData.delta_last15_p === "number"
                ? `${roundTwoDecimal(resData.delta_last15_p).toFixed(1)}`
                : "－";
            rowDate.average_of_last15_c =
              typeof resData.average_of_last15_c === "number"
                ? `${roundTwoDecimal(resData.average_of_last15_c).toFixed(1)}`
                : "－";
            rowDate.coefficient_m =
              typeof resData.coefficient_m === "number"
                ? `${roundTwoDecimal(resData.coefficient_m).toFixed(1)}`
                : "－";
            rowDate.control_value =
              typeof resData.control_value === "number"
                ? `${roundTwoDecimal(resData.control_value).toFixed(1)}`
                : "－";

            if (rowDate.supply != "－") numList.push(resData.supply);
            if (rowDate.demand != "－") numList.push(resData.demand);
          }
        });

        //一覧表更新
        setRe100TimelyData(() => {
          const max = Math.ceil(Math.max.apply(null, numList));
          return (
            <>
              <TableContainer
                component={Paper}
                className="tableContents re100TimelyContents"
              >
                <Table aria-label="simple table" className="re100TimelyTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div>時刻</div>
                        <div></div>
                      </TableCell>
                      <TableCell>
                        <div>30分間の</div>
                        <div>充電可能量(kWh)</div>
                      </TableCell>
                      <TableCell>
                        <div>累積</div>
                        <div>使用電力量(kWh)</div>
                      </TableCell>
                      <TableCell>
                        <div>ΔP</div>
                        <div>(kWh)</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <span>ΔP</span>
                          <span className="smallFont">last15</span>
                        </div>
                        <div>(kWh)</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <div className="borderTop">
                            <span>C</span>
                            <span className="smallFont">last15</span>
                          </div>
                        </div>
                        <div>(kW)</div>
                      </TableCell>
                      <TableCell>
                        <div>M</div>
                        <div></div>
                      </TableCell>
                      <TableCell>
                        <div>C</div>
                        <div>(kW)</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row) => {
                      return (
                        <TableRow key={row.calculated_at}>
                          <TableCell scope="row">{row.calculated_at}</TableCell>
                          <TableCell>{row.supply}</TableCell>
                          <TableCell>{row.demand}</TableCell>
                          <TableCell>{row.delta_p}</TableCell>
                          <TableCell>{row.delta_last15_p}</TableCell>
                          <TableCell>{row.average_of_last15_c}</TableCell>
                          <TableCell>{row.coefficient_m}</TableCell>
                          <TableCell>{row.control_value}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="re100TimelyGraphArea">
                <Box className="re100TimelyGraph">
                  <Box id="re100TimelyGraphLabel">kWh</Box>
                  <ResponsiveContainer>
                    <LineChart data={rowsList}>
                      <XAxis
                        dataKey="calculated_at"
                        padding={{ left: 20, right: 20 }}
                        ticks={rowsList.map((row: keyTypeString) => {
                          return row.calculated_at;
                        })}
                      />
                      <YAxis
                        type="number"
                        domain={[0, getYaxisDomainMax(max)]}
                        tickSize={6}
                      />
                      <Tooltip />
                      <Line
                        name="充電可能量"
                        unit="kWh"
                        dataKey="supply"
                        type="monotone"
                        stroke="#ff0000"
                        fill="#ff0000"
                        dot={false}
                      />
                      <Line
                        name="累積使用電力量"
                        unit="kWh"
                        dataKey="demand"
                        type="monotone"
                        stroke="#1976d2"
                        fill="#1976d2"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </>
          );
        });
      })
      .catch((err) => {
        setRe100TimelyData(getErrMsg(err));
      });
  };

  useEffect(() => {
    //RE100需給計画・実績(5分単位) セレクトボックスの初期選択
    setSelectTime(initialTime);
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="re100_1">
        <Box component="div" className="tableTitle">
          【需給計画・実績】(5分単位)
        </Box>
        <Box
          component="div"
          className="datePickerArea"
          id="re100TimelyDatePickerArea"
        >
          <DatePicker
            id="re100TimelyPicker"
            locale="ja"
            selected={moment(targetTime.start).toDate()}
            onChange={handleOutputStartDate}
            customInput={
              <Button
                variant="outlined"
                endIcon={<EventIcon />}
                sx={{ color: "#616161", borderColor: "#616161" }}
              >
                {moment(targetTime.start).format("YYYY/MM/DD")}
              </Button>
            }
          />
          <Select
            native
            id="re100TimelySelectbox"
            value={selectTime}
            onChange={handleChange}
          >
            {selectTimeList.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            className="updateBtn"
            id="re100TimelyUpdateBtn"
            onClick={updateRows}
          >
            表示
          </Button>
        </Box>
        <Box component="div">{re100TimelyData}</Box>
      </Box>
    </>
  );
};

export default Re100Timely;
