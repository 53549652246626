import "../css/HeaderContents.css";
import "@aws-amplify/ui-react/styles.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { getApiSetData, getErrMsg, keyTypeString } from "./Common";
import { connectStr } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

//URLから取得するパラメータ名
const URL_PRM_NAME_MAP = {
  org_id: "company",
};

/**
 * URLから全てのパラメータを取得
 * @param {*} list
 */
const getUrlPrm = (path: string) => {
  const search = new URL(path).search;
  const map: keyTypeString = {};
  const params = new URLSearchParams(search);
  params.forEach((value, key) => {
    map[key] = value;
  });

  return map;
};

/**
 * メイン部の表示を切り替える
 * @param jdg
 */
const chgDisp = (jdg: boolean) => {
  const elmMainArea = document.getElementById("mainArea") as HTMLDivElement;
  if (!elmMainArea) return;
  if (!jdg) {
    elmMainArea.classList.add("hidden");
  } else {
    elmMainArea.classList.remove("hidden");
  }
};

/**
 * メイン部の表示を更新する
 */
const handleAllDate = () => {
  const elmUpdateBtn = document.getElementsByClassName(
    "updateBtn"
  ) as HTMLCollectionOf<HTMLElement>;
  if (!elmUpdateBtn) return;
  chgDisp(true);
  for (const elm of elmUpdateBtn) {
    elm.click();
  }
};

const HeaderContents: React.FC = () => {
  const [headerContentsData, setHeaderContentsData] = useState(
    <span>Now Loading...</span>
  );

  const orgList: Array<keyTypeString> = [];
  const tmpList: any = [];

  const getMe = async () => {
    // API実行
    const dataMap = await getApiSetData();
    const path = `/me`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const prmMap = getUrlPrm(response.top_path);
        return prmMap && prmMap[URL_PRM_NAME_MAP.org_id]
          ? prmMap[URL_PRM_NAME_MAP.org_id]
          : "";
      })
      .then(async (org_id) => {
        if (!org_id) {
          await getOrgsList();
        } else {
          await getOrgs(org_id);
        }
      })
      .catch((err) => {
        setHeaderContentsData(getErrMsg(err));
        chgDisp(false);
      });
  };

  const getOrgs = async (org_id: string) => {
    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${org_id}`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then(async (response) => {
        //事業者の取得が0件
        if (response.length == 0) {
          chgDisp(false);
          return;
        }

        orgList.push({ org_id: response.org_id, org_name: response.org_name });
        await getLocsList(response.org_id);
      })
      .then(() => {
        setHeaderContentsData(() => {
          if (tmpList.length == 0) {
            chgDisp(false);
            return <span>表示可能なエリアがありません</span>;
          } else {
            chgDisp(true);
            return (
              <>
                <FormControl>
                  <Select native defaultValue="" id="targetArea">
                    {tmpList.map((data: symbol) => {
                      return data;
                    })}
                    ;
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  id="dispChgBtn"
                  onClick={handleAllDate}
                >
                  表示
                </Button>
              </>
            );
          }
        });
      })
      .then(() => {
        const elmDispChgBtn = document.getElementById(
          "dispChgBtn"
        ) as HTMLSelectElement;
        if (!elmDispChgBtn) return;
        elmDispChgBtn.click();
      })
      .catch((err) => {
        setHeaderContentsData(getErrMsg(err));
        chgDisp(false);
      });
  };

  const getOrgsList = async () => {
    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then(async (response) => {
        for (const resData of response) {
          orgList.push({ org_id: resData.org_id, org_name: resData.org_name });
          await getLocsList(resData.org_id);
        }
      })
      .then(() => {
        setHeaderContentsData(() => {
          if (tmpList.length == 0) {
            chgDisp(false);
            return <span>表示可能なエリアがありません</span>;
          } else {
            chgDisp(true);
            return (
              <>
                <FormControl>
                  <Select native defaultValue="" id="targetArea">
                    {tmpList.map((data: symbol) => {
                      return data;
                    })}
                    ;
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  id="dispChgBtn"
                  onClick={handleAllDate}
                >
                  表示
                </Button>
              </>
            );
          }
        });
      })
      .then(() => {
        const elmDispChgBtn = document.getElementById(
          "dispChgBtn"
        ) as HTMLSelectElement;
        if (!elmDispChgBtn) return;
        elmDispChgBtn.click();
      })
      .catch((err) => {
        setHeaderContentsData(getErrMsg(err));
        chgDisp(false);
      });
  };

  const getLocsList = async (org_id: string) => {
    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${org_id}/locs`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        //拠点の取得が0件
        if (Object.keys(response).length == 0) return null;
        tmpList.push(
          <optgroup key={org_id} label={orgList[orgList.length - 1].org_name}>
            {response.map((num: any) => {
              return (
                <option
                  key={num.loc_id}
                  value={`${org_id}${connectStr}${num.loc_id}`}
                >
                  {num.loc_name}
                </option>
              ); // org_idとlocs_idをConfig.tsxのconnectStrで繋げている
            })}
            ;
          </optgroup>
        );
      })
      .catch((err) => {
        setHeaderContentsData(getErrMsg(err));
        chgDisp(false);
      });
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      <Box component="div">
        <header>
          <span>
            RE100 (
            <span
              onClick={() => {
                window.location.href = "#re100_1";
              }}
            >
              5分単位
            </span>
            <span
              onClick={() => {
                window.location.href = "#re100_2";
              }}
            >
              日報
            </span>
            <span
              onClick={() => {
                window.location.href = "#re100_3";
              }}
            >
              月報
            </span>
            )
          </span>
          <span>
            DWPT(路) (
            <span
              onClick={() => {
                window.location.href = "#dwpt_1";
              }}
            >
              制御内容
            </span>
            <span
              onClick={() => {
                window.location.href = "#dwpt_2";
              }}
            >
              瞬時
            </span>
            <span
              onClick={() => {
                window.location.href = "#dwpt_3";
              }}
            >
              30分毎
            </span>
            )
          </span>
        </header>
        <h1 className="contentBox">対象エリア</h1>
        <div className="selectTargetArea">{headerContentsData}</div>
      </Box>
    </>
  );
};

export default HeaderContents;
