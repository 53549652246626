import "../css/DwptStatus.css";
import "@aws-amplify/ui-react/styles.css";

import { Button } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  getApiSetData,
  getErrMsg,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
import { connectStr } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// DWPT(路) 充電状態(瞬時) ラベルを渡す
const getOperationModeLabel = (str: string | null) => {
  if (!str) return "－";
  const operation_mode_label_map: keyTypeString = {
    charging: "充電",
    "standby-charging": "充電待機",
    discharging: "放電",
    "standby-discharging": "放電待機",
    stop: "停止",
    error: "異常",
    others: "その他",
  };

  return !operation_mode_label_map[str]
    ? "－"
    : `${operation_mode_label_map[str]}`;
};

const getCollection_result = (result: boolean | null) => {
  if (result == true) {
    return "成功";
  } else if (result == false) {
    return "失敗";
  } else {
    return "－";
  }
};

// DWPT(路) 充電状態(瞬時) 一覧表表示データ テンプレート
const createData = (
  name: string,
  collected_at: string,
  collection_result: string,
  operation_mode: string,
  ac_power: string,
  ac_cumulative_power: string,
  error_status: string | null,
  resource_id: string
) => {
  return {
    name,
    collected_at,
    collection_result,
    operation_mode,
    ac_power,
    ac_cumulative_power,
    error_status,
    resource_id,
  };
};

// DWPT(路) 充電状態(瞬時) 1件のレスポンスデータの型
type getData = {
  name: string; //必須
  device_id: string; //必須
  resource_id: string; //必須
  collected_at: string | null;
  collection_result: boolean | null;
  operation_mode: string | null;
  ac_power: number | null;
  ac_cumulative_power: number | null;
  error_status: string | null;
};

type setData = {
  name: string;
  collected_at: string;
  collection_result: string;
  operation_mode: string;
  ac_power: string;
  ac_cumulative_power: string;
  error_status: string | null;
  resource_id: string;
};

/**
 * 画面の描画
 */
const DwptStatus: React.FC = () => {
  const [dwptStatusData, setDwptStatusData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/dwpt_road/current_status`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const rowsList: Array<setData> = [];
        response.forEach((resData: getData) => {
          const collected_at = !resData.collected_at
            ? "－"
            : moment(resData.collected_at).format("YYYY/MM/DD HH:mm");

          //falseの場合、operation_mode, ac_power, ac_cumulative_power, error_statusが空となる
          if (resData.collection_result == false) {
            resData.operation_mode = null;
            resData.ac_power = null;
            resData.ac_cumulative_power = null;
            resData.error_status = null;
          }

          const collection_result = getCollection_result(
            resData.collection_result
          );
          const operation_mode = getOperationModeLabel(resData.operation_mode);

          //非数なら"－"を表示
          const ac_power =
            typeof resData.ac_power === "number"
              ? roundTwoDecimal(Math.abs(resData.ac_power)).toFixed(1)
              : "－";
          const ac_cumulative_power =
            typeof resData.ac_cumulative_power === "number"
              ? roundTwoDecimal(Math.abs(resData.ac_cumulative_power)).toFixed(
                  1
                )
              : "－";

          const error_status =
            resData.operation_mode == "error" ? resData.error_status : "なし";

          rowsList.push(
            //collected_atが無効な日付の場合は"－"を表示、operation_modeが空ならerror_statusは"－"を表示
            createData(
              resData.name,
              collected_at == "Invalid date" ? "－" : collected_at,
              collection_result,
              operation_mode,
              ac_power,
              ac_cumulative_power,
              !resData.operation_mode ? "－" : error_status,
              resData.resource_id
            )
          );
        });

        setDwptStatusData(() => {
          if (rowsList.length == 0) {
            return <span>表示するデータがありません</span>;
          } else {
            return (
              <TableContainer
                component={Paper}
                className="tableContents dwptStatusContents"
              >
                <Table aria-label="simple table" className="dwptStatusTable">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>状態取得時刻</TableCell>
                      <TableCell>状態取得結果</TableCell>
                      <TableCell>運転状態</TableCell>
                      <TableCell>交流電流(kW)</TableCell>
                      <TableCell>AC積載電力量(kWh)</TableCell>
                      <TableCell>異常</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row) => {
                      return (
                        <TableRow key={row.resource_id}>
                          <TableCell scope="row" title={row.name}>
                            {row.name}
                          </TableCell>
                          <TableCell>{row.collected_at}</TableCell>
                          <TableCell>{row.collection_result}</TableCell>
                          <TableCell>{row.operation_mode}</TableCell>
                          <TableCell>{row.ac_power}</TableCell>
                          <TableCell>{row.ac_cumulative_power}</TableCell>
                          <TableCell>{row.error_status}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        });
      })
      .catch((err) => {
        setDwptStatusData(getErrMsg(err));
      });
  };

  useEffect(() => {
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="dwpt_2">
        <Box component="div" className="tableTitle">
          DWPTの充電状態(瞬時)
        </Box>
        <Box component="div">{dwptStatusData}</Box>
        <Button className="hiddenBtn updateBtn" onClick={updateRows}></Button>
      </Box>
    </>
  );
};

export default DwptStatus;
