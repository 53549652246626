import { Auth } from "aws-amplify";

import { errMsgTmp, errStatusMap } from "./config/Config";

//keyの型定義
export interface keyTypeString {
  [key: string]: string;
}

export interface keyTypeNumber {
  [key: number]: string;
}

/**
 * 配列、オブジェクトのコピー
 */
export const cloneData = (d: any) => {
  if (!d || Object.keys(d).length == 0) return null;
  const s = JSON.stringify(d, null, " ");
  const o = JSON.parse(s);
  return o;
};

/**
 * 画面に表示するエラーメッセージを渡す
 * @param e
 */
export const getErrMsg = (e: any) => {
  try {
    const errMsg: string | undefined = errStatusMap[e.response.status];

    //特定のステータスコードの場合
    if (errMsg) return errMsgTmp(errMsg);

    const num = e.response.status / 100;
    const code = parseInt(numFormat(num, 0), 10) * 100;

    //ステータスコードが400か500かつレスポンスデータにメッセージが含まれる場合
    if (
      (code == 400 || code == 500) &&
      e.response.data &&
      e.response.data.failureDescription
    ) {
      const bodyMsg = `${e.response.data.failureDescription}(${e.response.data.failureCode})`;
      return errMsgTmp(bodyMsg);
    }

    //ステータスコードを表示
    return errMsgTmp(`${errStatusMap[99999]}(${e.response.status})`);
  } catch (error) {
    //レスポンスデータにステータスコードが無い場合
    return errMsgTmp(`${errStatusMap[99999]}(900)`);
  }
};

type setOption = {
  useGrouping: boolean;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

/**
 * 数値をフォーマット
 * @param {*} num
 */
export const numFormat = (num: number, n: number) => {
  const option = {
    useGrouping: true,
    minimumFractionDigits: n,
    maximumFractionDigits: n,
  };
  return numFormatWithOption(num, n, option);
};

export const numFormatWithOption = (
  num: number,
  n: number,
  option: setOption
) => {
  const a = Math.pow(10, n);
  num = Math.floor(num * a) / a;
  return new Intl.NumberFormat("ja-JP", option).format(num);
};

/**
 * Y軸の最大値を渡す
 * @param max
 */
export const getYaxisDomainMax = (max: number) => {
  const len1 = Math.floor(max).toString().length;
  const len2 = Math.floor(max / 3).toString().length;
  const base = Math.pow(10, len1 - (1 + len1 - len2)) * (1 + (len1 - len2) * 4);
  const nMax = Math.ceil(max / base) * base;
  console.log(`${max}: ${base} --> ${nMax}`);
  return nMax;
};

/**
 * 小数第二位で四捨五入する
 * @param value
 * @param num
 */
export const roundTwoDecimal = (num: number) => {
  return Math.round(num * 10) / 10;
};

/**
 * API実行に必要なデータ
 */
export const getApiSetData = async () => {
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Content-Type": "application/json;",
    },
  };

  return { apiName: "MyAPIGatewayAPI", header: myInit };
};
