import "../css/VehicleInformation.css";
import "@aws-amplify/ui-react/styles.css";

import { Button } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useState }  from "react";

import {
  getApiSetData,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
import { connectStr } from "./config/Config";

const getOperationCommandLabel = (str: string | null) => {
  if (!str) return "－";
  const operation_command_label_map: keyTypeString = {
    charging: "充電",
    discharging: "放電",
    stop: "停止",
    "error-reset": "異常リセット",
  };

  return !operation_command_label_map[str]
    ? "－"
    : `${operation_command_label_map[str]}`;
};

const getOperationModeLabel = (str: string | null) => {
  if (!str) return "－";
  const operation_mode_label_map: keyTypeString = {
    charging: "充電",
    "standby-charging": "充電待機",
    discharging: "放電",
    "standby-discharging": "放電待機",
    stop: "停止",
    error: "異常"
  };

  return !operation_mode_label_map[str]
    ? "－"
    : `${operation_mode_label_map[str]}`;
};

// 車両状態 1件のレスポンスデータ型
type getData = {
  terminal_code: string;
  collected_at: string | null;
  operation_command: string | null;
  operation_mode: string | null;
  charging_power_kw: number | null;
  error_status: string | null;
  speed: number | null;
  direction: number | null;
  latitude: number | null;
  longitude: number | null;
  soc: number | null;
};

type setData = {
  terminal_code: string,
  collected_at: string,
  operation_command: string,
  operation_mode: string,
  charging_power_kw: string,
  error_status: string | null,
  speed: string,
  location: string
};

// 車両状態 一覧表表示データ テンプレート
const createData = (
  terminal_code: string,
  collected_at: string,
  operation_command: string,
  operation_mode: string,
  charging_power_kw: string,
  error_status: string | null,
  speed: string,
  location: string
) => {
  return { terminal_code, collected_at, operation_command, operation_mode, charging_power_kw, error_status, speed, location };
};

const VehicleInfo: React.FC = () => {
    const [vehicleInfoData, setvehicleInfoData] = useState(
        <span>Now Loading...</span>
      );

    const updateRows = async () => {
      const elmTargetArea = document.getElementById(
        "targetArea"
      ) as HTMLSelectElement;
      if (!elmTargetArea) return;
      //valueにはorg_idとlocs_idと繋げた値が入っている
      const valueList = elmTargetArea.value.split(connectStr);

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/dwpt_vehicle/status`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const rowsList: Array<setData> = [];
        response.forEach((resData: getData) => {
          const collected_at = !resData.collected_at
          ? "－"
          : moment(resData.collected_at).format("YYYY/MM/DD HH:mm");

          const operation_command = getOperationCommandLabel(resData.operation_command);
          const operation_mode = getOperationModeLabel(resData.operation_mode);

          const charging_power_kw = 
            typeof resData.charging_power_kw === "number"
              ? roundTwoDecimal(Math.abs(resData.charging_power_kw)).toFixed(1)
              : "－";
          
          const error_status = 
            resData.operation_mode == "error" ? resData.error_status : "なし";

          let speed = "－";
          if (typeof resData.speed === "number" && typeof resData.direction === "number") {
            speed = `${resData.speed}km/h(${resData.direction}°)`;
          }

          let location = "－";
          if (typeof resData.longitude === "number" && typeof resData.latitude === "number") {
            location = `${resData.longitude}, ${resData.latitude}`;
          }

          rowsList.push(
            createData(
              resData.terminal_code,
              collected_at,
              operation_command,
              operation_mode,
              charging_power_kw,
              error_status,
              speed,
              location
            )
          );    
        });

        setvehicleInfoData(() => {
          if (rowsList.length == 0) {
            return <span>表示するデータがありません</span>;
          } else {
            return (
              <TableContainer
                component={Paper}
                className="tableContents vehicleInfoContents"
              >
                <Table aria-label="simple table" className="vehicleInfoTable">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>状態取得時刻</TableCell>
                      <TableCell>運転指令</TableCell>
                      <TableCell>運転状態</TableCell>
                      <TableCell>充電電力(kW)</TableCell>
                      <TableCell>異常</TableCell>
                      <TableCell>速度</TableCell>
                      <TableCell>位置(経度, 緯度)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row) => {
                      return (
                        <TableRow key={row.terminal_code}>
                          <TableCell>{row.terminal_code}</TableCell>
                          <TableCell>{row.collected_at}</TableCell>
                          <TableCell>{row.operation_command}</TableCell>
                          <TableCell>{row.operation_mode}</TableCell>
                          <TableCell>{row.charging_power_kw}</TableCell>
                          <TableCell>{row.error_status}</TableCell>
                          <TableCell>{row.speed}</TableCell>
                          <TableCell>{row.location}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        }); 
      });
    };

    return (
        <>
          <Box component="div" className="tablePosition anchor" id="dwpt_1">
            <Box component="div" className="tableTitle">
              車両状態
            </Box>
            <Box component="div">{vehicleInfoData}</Box>
            <Button className="hiddenBtn updateBtn" onClick={updateRows}></Button>
          </Box>
        </>
    );
}

export default VehicleInfo;