import "../css/DwptReports.css";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import EventIcon from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import ja from "date-fns/locale/ja";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import {
  cloneData,
  getApiSetData,
  getErrMsg,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
import { connectStr, dwptReportTmp } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// DatePickerを日本語に設定
registerLocale("ja", ja);

/**
 * 画面の描画
 */
const DwptReports: React.FC = () => {
  const [targetTime, setTargetTime] = useState({
    start: moment(),
    end: moment(),
  });
  const handleOutputStartDate = (date: Date) => {
    setTargetTime((data) => ({
      ...data,
      start: moment(date),
    }));
  };

  const [dwptReportsData, setDwptReportsData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    //日付テキストボックス
    const elmDwptDatePicker = document.getElementById(
      "dwptDatePicker"
    ) as HTMLButtonElement;
    const YMD = !elmDwptDatePicker.value
      ? "－"
      : moment(elmDwptDatePicker.value).format("YYYY-MM-DD");

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/dwpt_road/daily_reports/${YMD}`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const rowsList: Array<keyTypeString> = [];
        const keyList: Array<string> = [];
        for (const key in dwptReportTmp) {
          keyList.push(key);
        }
        response.forEach((resData: any) => {
          const rowData = cloneData(dwptReportTmp);
          rowData.name = resData.name;
          rowData.resource_id = resData.resource_id;
          resData.loads.forEach((key: any) => {
            const num = key.unittime_of_day + 1;
            if (num >= 1) {
              //非数なら"－"を表示
              rowData[keyList[num]] =
                typeof key.load === "number"
                  ? roundTwoDecimal(key.load).toFixed(1)
                  : "－";
            }
          });

          rowsList.push(rowData);
        });

        setDwptReportsData(() => {
          if (rowsList.length == 0) {
            return <span>表示するデータがありません</span>;
          } else {
            keyList[0] = "使用電力量(kWh)";
            return (
              <TableContainer
                component={Paper}
                className="tableContents dwptReportsContents"
              >
                <Table aria-label="simple table" className="dwptReportsTable">
                  <TableHead>
                    <TableRow key={"dwptReport_header"}>
                      {keyList.map((value: string, key: number) => {
                        return (
                          <TableCell
                            scope="row"
                            key={`dwptReport_header_${key}`}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row) => {
                      return (
                        <TableRow key={row.resource_id}>
                          <TableCell scope="row" title={row.name}>
                            {row.name}
                          </TableCell>
                          <TableCell>{row["00:00"]}</TableCell>
                          <TableCell>{row["00:30"]}</TableCell>
                          <TableCell>{row["01:00"]}</TableCell>
                          <TableCell>{row["01:30"]}</TableCell>
                          <TableCell>{row["02:00"]}</TableCell>
                          <TableCell>{row["02:30"]}</TableCell>
                          <TableCell>{row["03:00"]}</TableCell>
                          <TableCell>{row["03:30"]}</TableCell>
                          <TableCell>{row["04:00"]}</TableCell>
                          <TableCell>{row["04:30"]}</TableCell>
                          <TableCell>{row["05:00"]}</TableCell>
                          <TableCell>{row["05:30"]}</TableCell>
                          <TableCell>{row["06:00"]}</TableCell>
                          <TableCell>{row["06:30"]}</TableCell>
                          <TableCell>{row["07:00"]}</TableCell>
                          <TableCell>{row["07:30"]}</TableCell>
                          <TableCell>{row["08:00"]}</TableCell>
                          <TableCell>{row["08:30"]}</TableCell>
                          <TableCell>{row["09:00"]}</TableCell>
                          <TableCell>{row["09:30"]}</TableCell>
                          <TableCell>{row["10:00"]}</TableCell>
                          <TableCell>{row["10:30"]}</TableCell>
                          <TableCell>{row["11:00"]}</TableCell>
                          <TableCell>{row["11:30"]}</TableCell>
                          <TableCell>{row["12:00"]}</TableCell>
                          <TableCell>{row["12:30"]}</TableCell>
                          <TableCell>{row["13:00"]}</TableCell>
                          <TableCell>{row["13:30"]}</TableCell>
                          <TableCell>{row["14:00"]}</TableCell>
                          <TableCell>{row["14:30"]}</TableCell>
                          <TableCell>{row["15:00"]}</TableCell>
                          <TableCell>{row["15:30"]}</TableCell>
                          <TableCell>{row["16:00"]}</TableCell>
                          <TableCell>{row["16:30"]}</TableCell>
                          <TableCell>{row["17:00"]}</TableCell>
                          <TableCell>{row["17:30"]}</TableCell>
                          <TableCell>{row["18:00"]}</TableCell>
                          <TableCell>{row["18:30"]}</TableCell>
                          <TableCell>{row["19:00"]}</TableCell>
                          <TableCell>{row["19:30"]}</TableCell>
                          <TableCell>{row["20:00"]}</TableCell>
                          <TableCell>{row["20:30"]}</TableCell>
                          <TableCell>{row["21:00"]}</TableCell>
                          <TableCell>{row["21:30"]}</TableCell>
                          <TableCell>{row["22:00"]}</TableCell>
                          <TableCell>{row["22:30"]}</TableCell>
                          <TableCell>{row["23:00"]}</TableCell>
                          <TableCell>{row["23:30"]}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        });
      })
      .catch((err) => {
        setDwptReportsData(getErrMsg(err));
      });
  };

  useEffect(() => {
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="dwpt_3">
        <Box component="div" className="tableTitle">
          DWPTの充電状態(30分毎)
        </Box>
        <Box
          component="div"
          className="datePickerArea"
          id="dwptReportDatePicker"
        >
          <DatePicker
            id="dwptDatePicker"
            locale="ja"
            selected={moment(targetTime.start).toDate()}
            onChange={handleOutputStartDate}
            customInput={
              <Button
                variant="outlined"
                endIcon={<EventIcon />}
                sx={{ color: "#616161", borderColor: "#616161" }}
              >
                {moment(targetTime.start).format("YYYY/MM/DD")}
              </Button>
            }
          />
          <Button
            variant="contained"
            color="primary"
            className="updateBtn"
            onClick={updateRows}
          >
            表示
          </Button>
        </Box>
        <Box component="div">{dwptReportsData}</Box>
      </Box>
    </>
  );
};

export default DwptReports;
