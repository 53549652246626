import React from "react";

import { keyTypeNumber } from "../Common";

// RE100需給計画・実績(5分単位) セレクトボックス
export const selectTimeList = [
  "00:00 ‐ 00:25",
  "00:30 ‐ 00:55",
  "01:00 ‐ 01:25",
  "01:30 ‐ 01:55",
  "02:00 ‐ 02:25",
  "02:30 ‐ 02:55",
  "03:00 ‐ 03:25",
  "03:30 ‐ 03:55",
  "04:00 ‐ 04:25",
  "04:30 ‐ 04:55",
  "05:00 ‐ 05:25",
  "05:30 ‐ 05:55",
  "06:00 ‐ 06:25",
  "06:30 ‐ 06:55",
  "07:00 ‐ 07:25",
  "07:30 ‐ 07:55",
  "08:00 ‐ 08:25",
  "08:30 ‐ 08:55",
  "09:00 ‐ 09:25",
  "09:30 ‐ 09:55",
  "10:00 ‐ 10:25",
  "10:30 ‐ 10:55",
  "11:00 ‐ 11:25",
  "11:30 ‐ 11:55",
  "12:00 ‐ 12:25",
  "12:30 ‐ 12:55",
  "13:00 ‐ 13:25",
  "13:30 ‐ 13:55",
  "14:00 ‐ 14:25",
  "14:30 ‐ 14:55",
  "15:00 ‐ 15:25",
  "15:30 ‐ 15:55",
  "16:00 ‐ 16:25",
  "16:30 ‐ 16:55",
  "17:00 ‐ 17:25",
  "17:30 ‐ 17:55",
  "18:00 ‐ 18:25",
  "18:30 ‐ 18:55",
  "19:00 ‐ 19:25",
  "19:30 ‐ 19:55",
  "20:00 ‐ 20:25",
  "20:30 ‐ 20:55",
  "21:00 ‐ 21:25",
  "21:30 ‐ 21:55",
  "22:00 ‐ 22:25",
  "22:30 ‐ 22:55",
  "23:00 ‐ 23:25",
  "23:30 ‐ 23:55",
];

// RE100需給計画・実績(5分単位) 一覧表の表示時刻
export const timeList = [
  {
    start_time: "00:00",
    items: ["00:00", "00:05", "00:10", "00:15", "00:20", "00:25"],
  },
  {
    start_time: "00:30",
    items: ["00:30", "00:35", "00:40", "00:45", "00:50", "00:55"],
  },
  {
    start_time: "01:00",
    items: ["01:00", "01:05", "01:10", "01:15", "01:20", "01:25"],
  },
  {
    start_time: "01:30",
    items: ["01:30", "01:35", "01:40", "01:45", "01:50", "01:55"],
  },
  {
    start_time: "02:00",
    items: ["02:00", "02:05", "02:10", "02:15", "02:20", "02:25"],
  },
  {
    start_time: "02:30",
    items: ["02:30", "02:35", "02:40", "02:45", "02:50", "02:55"],
  },
  {
    start_time: "03:00",
    items: ["03:00", "03:05", "03:10", "03:15", "03:20", "03:25"],
  },
  {
    start_time: "03:30",
    items: ["03:30", "03:35", "03:40", "03:45", "03:50", "03:55"],
  },
  {
    start_time: "04:00",
    items: ["04:00", "04:05", "04:10", "04:15", "04:20", "04:25"],
  },
  {
    start_time: "04:30",
    items: ["04:30", "04:35", "04:40", "04:45", "04:50", "04:55"],
  },
  {
    start_time: "05:00",
    items: ["05:00", "05:05", "05:10", "05:15", "05:20", "05:25"],
  },
  {
    start_time: "05:30",
    items: ["05:30", "05:35", "05:40", "05:45", "05:50", "05:55"],
  },
  {
    start_time: "06:00",
    items: ["06:00", "06:05", "06:10", "06:15", "06:20", "06:25"],
  },
  {
    start_time: "06:30",
    items: ["06:30", "06:35", "06:40", "06:45", "06:50", "06:55"],
  },
  {
    start_time: "07:00",
    items: ["07:00", "07:05", "07:10", "07:15", "07:20", "07:25"],
  },
  {
    start_time: "07:30",
    items: ["07:30", "07:35", "07:40", "07:45", "07:50", "07:55"],
  },
  {
    start_time: "08:00",
    items: ["08:00", "08:05", "08:10", "08:15", "08:20", "08:25"],
  },
  {
    start_time: "08:30",
    items: ["08:30", "08:35", "08:40", "08:45", "08:50", "08:55"],
  },
  {
    start_time: "09:00",
    items: ["09:00", "09:05", "09:10", "09:15", "09:20", "09:25"],
  },
  {
    start_time: "09:30",
    items: ["09:30", "09:35", "09:40", "09:45", "09:50", "09:55"],
  },
  {
    start_time: "10:00",
    items: ["10:00", "10:05", "10:10", "10:15", "10:20", "10:25"],
  },
  {
    start_time: "10:30",
    items: ["10:30", "10:35", "10:40", "10:45", "10:50", "10:55"],
  },
  {
    start_time: "11:00",
    items: ["11:00", "11:05", "11:10", "11:15", "11:20", "11:25"],
  },
  {
    start_time: "11:30",
    items: ["11:30", "11:35", "11:40", "11:45", "11:50", "11:55"],
  },
  {
    start_time: "12:00",
    items: ["12:00", "12:05", "12:10", "12:15", "12:20", "12:25"],
  },
  {
    start_time: "12:30",
    items: ["12:30", "12:35", "12:40", "12:45", "12:50", "12:55"],
  },
  {
    start_time: "13:00",
    items: ["13:00", "13:05", "13:10", "13:15", "13:20", "13:25"],
  },
  {
    start_time: "13:30",
    items: ["13:30", "13:35", "13:40", "13:45", "13:50", "13:55"],
  },
  {
    start_time: "14:00",
    items: ["14:00", "14:05", "14:10", "14:15", "14:20", "14:25"],
  },
  {
    start_time: "14:30",
    items: ["14:30", "14:35", "14:40", "14:45", "14:50", "14:55"],
  },
  {
    start_time: "15:00",
    items: ["15:00", "15:05", "15:10", "15:15", "15:20", "15:25"],
  },
  {
    start_time: "15:30",
    items: ["15:30", "15:35", "15:40", "15:45", "15:50", "15:55"],
  },
  {
    start_time: "16:00",
    items: ["16:00", "16:05", "16:10", "16:15", "16:20", "16:25"],
  },
  {
    start_time: "16:30",
    items: ["16:30", "16:35", "16:40", "16:45", "16:50", "16:55"],
  },
  {
    start_time: "17:00",
    items: ["17:00", "17:05", "17:10", "17:15", "17:20", "17:25"],
  },
  {
    start_time: "17:30",
    items: ["17:30", "17:35", "17:40", "17:45", "17:50", "17:55"],
  },
  {
    start_time: "18:00",
    items: ["18:00", "18:05", "18:10", "18:15", "18:20", "18:25"],
  },
  {
    start_time: "18:30",
    items: ["18:30", "18:35", "18:40", "18:45", "18:50", "18:55"],
  },
  {
    start_time: "19:00",
    items: ["19:00", "19:05", "19:10", "19:15", "19:20", "19:25"],
  },
  {
    start_time: "19:30",
    items: ["19:30", "19:35", "19:40", "19:45", "19:50", "19:55"],
  },
  {
    start_time: "20:00",
    items: ["20:00", "20:05", "20:10", "20:15", "20:20", "20:25"],
  },
  {
    start_time: "20:30",
    items: ["20:30", "20:35", "20:40", "20:45", "20:50", "20:55"],
  },
  {
    start_time: "21:00",
    items: ["21:00", "21:05", "21:10", "21:15", "21:20", "21:25"],
  },
  {
    start_time: "21:30",
    items: ["21:30", "21:35", "21:40", "21:45", "21:50", "21:55"],
  },
  {
    start_time: "22:00",
    items: ["22:00", "22:05", "22:10", "22:15", "22:20", "22:25"],
  },
  {
    start_time: "22:30",
    items: ["22:30", "22:35", "22:40", "22:45", "22:50", "22:55"],
  },
  {
    start_time: "23:00",
    items: ["23:00", "23:05", "23:10", "23:15", "23:20", "23:25"],
  },
  {
    start_time: "23:30",
    items: ["23:30", "23:35", "23:40", "23:45", "23:50", "23:55"],
  },
];

export const dwptReportTmp = {
  name: "－",
  "00:00": "－",
  "00:30": "－",
  "01:00": "－",
  "01:30": "－",
  "02:00": "－",
  "02:30": "－",
  "03:00": "－",
  "03:30": "－",
  "04:00": "－",
  "04:30": "－",
  "05:00": "－",
  "05:30": "－",
  "06:00": "－",
  "06:30": "－",
  "07:00": "－",
  "07:30": "－",
  "08:00": "－",
  "08:30": "－",
  "09:00": "－",
  "09:30": "－",
  "10:00": "－",
  "10:30": "－",
  "11:00": "－",
  "11:30": "－",
  "12:00": "－",
  "12:30": "－",
  "13:00": "－",
  "13:30": "－",
  "14:00": "－",
  "14:30": "－",
  "15:00": "－",
  "15:30": "－",
  "16:00": "－",
  "16:30": "－",
  "17:00": "－",
  "17:30": "－",
  "18:00": "－",
  "18:30": "－",
  "19:00": "－",
  "19:30": "－",
  "20:00": "－",
  "20:30": "－",
  "21:00": "－",
  "21:30": "－",
  "22:00": "－",
  "22:30": "－",
  "23:00": "－",
  "23:30": "－",
};

// RE100需給計画・実績(30分単位) グラフデータテンプレート
export const re100DailyGraphTmp = [
  { time: "00:00", supply: "－", demand: "－" },
  { time: "00:30", supply: "－", demand: "－" },
  { time: "01:00", supply: "－", demand: "－" },
  { time: "01:30", supply: "－", demand: "－" },
  { time: "02:00", supply: "－", demand: "－" },
  { time: "02:30", supply: "－", demand: "－" },
  { time: "03:00", supply: "－", demand: "－" },
  { time: "03:30", supply: "－", demand: "－" },
  { time: "04:00", supply: "－", demand: "－" },
  { time: "04:30", supply: "－", demand: "－" },
  { time: "05:00", supply: "－", demand: "－" },
  { time: "05:30", supply: "－", demand: "－" },
  { time: "06:00", supply: "－", demand: "－" },
  { time: "06:30", supply: "－", demand: "－" },
  { time: "07:00", supply: "－", demand: "－" },
  { time: "07:30", supply: "－", demand: "－" },
  { time: "08:00", supply: "－", demand: "－" },
  { time: "08:30", supply: "－", demand: "－" },
  { time: "09:00", supply: "－", demand: "－" },
  { time: "09:30", supply: "－", demand: "－" },
  { time: "10:00", supply: "－", demand: "－" },
  { time: "10:30", supply: "－", demand: "－" },
  { time: "11:00", supply: "－", demand: "－" },
  { time: "11:30", supply: "－", demand: "－" },
  { time: "12:00", supply: "－", demand: "－" },
  { time: "12:30", supply: "－", demand: "－" },
  { time: "13:00", supply: "－", demand: "－" },
  { time: "13:30", supply: "－", demand: "－" },
  { time: "14:00", supply: "－", demand: "－" },
  { time: "14:30", supply: "－", demand: "－" },
  { time: "15:00", supply: "－", demand: "－" },
  { time: "15:30", supply: "－", demand: "－" },
  { time: "16:00", supply: "－", demand: "－" },
  { time: "16:30", supply: "－", demand: "－" },
  { time: "17:00", supply: "－", demand: "－" },
  { time: "17:30", supply: "－", demand: "－" },
  { time: "18:00", supply: "－", demand: "－" },
  { time: "18:30", supply: "－", demand: "－" },
  { time: "19:00", supply: "－", demand: "－" },
  { time: "19:30", supply: "－", demand: "－" },
  { time: "20:00", supply: "－", demand: "－" },
  { time: "20:30", supply: "－", demand: "－" },
  { time: "21:00", supply: "－", demand: "－" },
  { time: "21:30", supply: "－", demand: "－" },
  { time: "22:00", supply: "－", demand: "－" },
  { time: "22:30", supply: "－", demand: "－" },
  { time: "23:00", supply: "－", demand: "－" },
  { time: "23:30", supply: "－", demand: "－" },
  { time: "24:00", supply: "－", demand: "－" },
];

//RE100需給計画・実績(1日単位) セレクトボックスの期間
export const period = 11;

//エラーメッセージ一覧
export const errStatusMap: keyTypeNumber = {
  401: "認証情報の有効期限が切れました。再度ログインしてください(401)",
  403: "認証情報の有効期限が切れました。再度ログインしてください(403)",
  422: "問題が発生しました。ブラウザを閉じて再アクセスしてください(422)",
  99999: "問題が発生しました。ブラウザを閉じて再アクセスしてください",
};

//エラーメッセージのテンプレート
export const errMsgTmp = (str: string) => {
  return (
    <span className="errMsg" title={str}>
      {str}
    </span>
  );
};

//値を繋げる際に使用する文字列
export const connectStr = "\\\\";
