import "../css/Re100Daily.css";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import EventIcon from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import ja from "date-fns/locale/ja";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  cloneData,
  getApiSetData,
  getErrMsg,
  getYaxisDomainMax,
  keyTypeString,
  roundTwoDecimal,
} from "./Common";
import { connectStr, re100DailyGraphTmp } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// DatePickerを日本語に設定
registerLocale("ja", ja);

/**
 * 画面の描画
 */
const Re100Daily: React.FC = () => {
  //セレクトボックスの作成
  const [targetTime, setTargetTime] = useState({
    start: moment(),
    end: moment(),
  });
  const handleOutputStartDate = (date: Date) => {
    setTargetTime((data) => ({
      ...data,
      start: moment(date),
    }));
  };

  const [re100DailyData, setRe100DailyData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    //日付テキストボックス
    const elmRe100DailyDatePicker = document.getElementById(
      "re100DailyDatePicker"
    ) as HTMLButtonElement;
    const YMD = !elmRe100DailyDatePicker.value
      ? "－"
      : moment(elmRe100DailyDatePicker.value).format("YYYY-MM-DD");

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/re100/supply_and_demand/daily_reports/${YMD}`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const supplyList = Array(49).fill("－");
        supplyList[0] = "充電可能量(kWh)";
        const demandList = Array(49).fill("－");
        demandList[0] = "使用電力量(kWh)";
        const diffList = Array(49).fill("－");
        diffList[0] = "差分(kWh)";
        const reEnergyList = Array(49).fill("－");
        reEnergyList[0] = "再エネ率(%)";
        const graphList = cloneData(re100DailyGraphTmp);

        const rowsList: Array<Array<string>> = [];
        const numList: Array<number> = [];
        response.forEach((resData: any) => {
          const num = resData.unittime_of_day + 1;
          if (num >= 1 && num <= 48) {
            if (resData.supply == null && resData.demand == null) {
              supplyList[num] = "－";
              demandList[num] = "－";
              diffList[num] = "－";
              reEnergyList[num] = "－";
            } else {
              const supply: string =
                resData.supply == null
                  ? "－"
                  : roundTwoDecimal(resData.supply).toFixed(1);
              const demand: string =
                resData.demand == null
                  ? "－"
                  : roundTwoDecimal(resData.demand).toFixed(1);
              supplyList[num] = supply;
              demandList[num] = demand;
              diffList[num] =
                resData.supply == null || resData.demand == null
                  ? "－"
                  : "" +
                    roundTwoDecimal(
                      parseFloat(supply) - parseFloat(demand)
                    ).toFixed(1);
              reEnergyList[num] =
                resData.demand == 0 ||
                resData.supply == null ||
                resData.demand == null
                  ? "－"
                  : `${roundTwoDecimal(
                      Math.min(1, parseFloat(supply) / parseFloat(demand)) * 100
                    ).toFixed(1)}%`;
              graphList[num - 1].supply = supply;
              graphList[num - 1].demand = demand;
              numList.push(supplyList[num]);
              numList.push(demandList[num]);
            }
          }
        });

        rowsList.push(supplyList, demandList, diffList, reEnergyList);

        setRe100DailyData(() => {
          const max = Math.ceil(Math.max.apply(null, numList));
          const headerList = re100DailyGraphTmp.map((data: keyTypeString) => {
            return data.time;
          });
          headerList.unshift("");
          headerList.pop();

          return (
            <>
              <TableContainer
                component={Paper}
                className="tableContents re100DailyContents"
              >
                <Table aria-label="simple table" className="re100DailyTable">
                  <TableHead>
                    <TableRow key={"re100Daily_header"}>
                      {headerList.map((value: string, key: number) => {
                        return (
                          <TableCell
                            scope="row"
                            key={`re100Daily_header_${key}`}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row, index) => {
                      return (
                        <TableRow key={`re100Daily_${index}`}>
                          {row.map((value: string, key: number) => {
                            return (
                              <TableCell
                                scope="row"
                                key={`re100Daily_${index}_${key}`}
                                title={value}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="re100DailyGraphArea">
                <Box className="re100DailyGraph">
                  <Box id="re100DailyGraphLabel">kWh</Box>
                  <ResponsiveContainer>
                    <BarChart data={graphList} barGap={1} barCategoryGap="20%">
                      <XAxis
                        dataKey="time"
                        padding={{ left: 20, right: 20 }}
                        ticks={[
                          "00:00",
                          "01:00",
                          "02:00",
                          "03:00",
                          "04:00",
                          "05:00",
                          "06:00",
                          "07:00",
                          "08:00",
                          "09:00",
                          "10:00",
                          "11:00",
                          "12:00",
                          "13:00",
                          "14:00",
                          "15:00",
                          "16:00",
                          "17:00",
                          "18:00",
                          "19:00",
                          "20:00",
                          "21:00",
                          "22:00",
                          "23:00",
                          "24:00",
                        ]}
                      />
                      <YAxis
                        type="number"
                        domain={[0, getYaxisDomainMax(max)]}
                        padding={{ top: 10 }}
                        tickSize={6}
                      />
                      <Tooltip />
                      <Bar
                        name="充電可能量"
                        unit="kWh"
                        dataKey="supply"
                        fill="#888"
                      />
                      <Bar
                        name="使用電力量"
                        unit="kWh"
                        dataKey="demand"
                        fill="#1976d2"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </>
          );
        });
      })
      .catch((err) => {
        setRe100DailyData(getErrMsg(err));
      });
  };

  useEffect(() => {
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="re100_2">
        <Box component="div" className="tableTitle">
          【日報:需給計画・実績】(30分単位)
        </Box>
        <Box
          component="div"
          className="datePickerArea"
          id="dwptReportDatePicker"
        >
          <DatePicker
            id="re100DailyDatePicker"
            locale="ja"
            selected={moment(targetTime.start).toDate()}
            onChange={handleOutputStartDate}
            customInput={
              <Button
                variant="outlined"
                endIcon={<EventIcon />}
                sx={{ color: "#616161", borderColor: "#616161" }}
              >
                {moment(targetTime.start).format("YYYY/MM/DD")}
              </Button>
            }
          />
          <Button
            variant="contained"
            color="primary"
            className="updateBtn"
            onClick={updateRows}
          >
            表示
          </Button>
        </Box>
        <Box component="div">{re100DailyData}</Box>
      </Box>
    </>
  );
};

export default Re100Daily;
