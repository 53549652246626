import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Amplify, Auth, I18n } from "aws-amplify";
import React from "react";

import { vocabularies } from "./components/config/CognitoJapaneseList";
// DWPT(路) 制御内容
import DwptOperation from "./components/DwptOperation";
// DWPT(路) 充電状態(30分毎)
import DwptReports from "./components/DwptReports";
// DWPT(路) 充電状態(瞬時)
import DwptStatus from "./components/DwptStatus";
// ヘッダ部
import HeaderContents from "./components/HeaderContents";
// RE100 (30分単位)
import Re100Daily from "./components/Re100Daily";
// RE100 (1日単位)
import Re100Monthly from "./components/Re100Monthly";
// RE100 (5分単位)
import Re100Timely from "./components/Re100Timely";
// 車両 (車両状態)
import VehicleInfo from "./components/VehicleInformation";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const config = window["runtimeConfig"];
config["graphql_headers"] = async () => ({
  // 認証情報にメールアドレスを含めるためにIDトークンでAppSyncにアクセスする
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});
Amplify.configure(config);

//日本語化
I18n.putVocabularies(translations);
I18n.setLanguage("ja");

//サーバエラーメッセージの日本語化
I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

function App() {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut }) => (
        <>
          <div className="headerArea">
            <span id="logout" onClick={signOut}>
              ログアウト
            </span>
            <HeaderContents />
          </div>

          <div id="mainArea" className="hidden">
            <div className="contentBox">
              <h1>RE100</h1>
              <Re100Timely />
              <Re100Daily />
              <Re100Monthly />
            </div>
            <div className="contentBox">
              <h1>DWPT(路)</h1>
              <DwptOperation />
              <DwptStatus />
              <DwptReports />
            </div>
            <div className="contentBox">
              <h1>車両</h1>
              <VehicleInfo />
            </div>
          </div>
          <div className="bulkArea"></div>
        </>
      )}
    </Authenticator>
  );
}

export default App;
