import "../css/Re100Monthly.css";
import "@aws-amplify/ui-react/styles.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { cloneData, getApiSetData, getErrMsg, roundTwoDecimal } from "./Common";
import { connectStr, period } from "./config/Config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

/**
 * セレクトボックスで選択中の月の最終日までの初期データを渡す
 * @param YM
 */
const getInitalDate = (YM: string) => {
  const date: Date = new Date(YM);
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const list = Array(lastDate + 1).fill("－");
  const headerList: Array<string> = [];
  list.forEach((value, key) => {
    headerList.push(`${key}日`);
  });

  headerList[0] = "";

  return { list: list, header: headerList };
};

/**
 * 画面の描画
 */
const Re100Monthly: React.FC = () => {
  //セレクトボックスの作成
  const [re100MonthlySelectDate] = useState(() => {
    const now: Date = new Date();
    const monthList: Array<any> = [];
    for (let i = period; i >= 0; i--) {
      const date = new Date(now.getFullYear(), now.getMonth() - i);
      const value = moment(date.toISOString()).format("YYYY/MM"); // toISOString()してるのにwarningでる
      monthList.push(
        <option key={`re100Monthly_option_${i}`} value={value}>
          {value}
        </option>
      );
    }
    return monthList.map((value: any) => {
      return value;
    });
  });

  //セレクトボックスの初期選択を今月にする
  const selectThisMonth = () => {
    const elmRe100MonthlySelect = document.getElementById(
      "re100MonthlySelect"
    ) as HTMLSelectElement;
    elmRe100MonthlySelect.selectedIndex = period;
  };

  const [re100MonthlyData, setRe100MonthlyData] = useState(
    <span>Now Loading...</span>
  );

  //一覧表更新
  const updateRows = async () => {
    const elmTargetArea = document.getElementById(
      "targetArea"
    ) as HTMLSelectElement;
    if (!elmTargetArea) return;
    //valueにはorg_idとlocs_idと繋げた値が入っている
    const valueList = elmTargetArea.value.split(connectStr);

    //セレクトボックス
    const elmRe100MonthlySelect = document.getElementById(
      "re100MonthlySelect"
    ) as HTMLSelectElement;
    const YM = !elmRe100MonthlySelect.value
      ? "－"
      : moment(elmRe100MonthlySelect.value).format("YYYY-MM");

    // API実行
    const dataMap = await getApiSetData();
    const path = `/web/orgs/${valueList[0]}/locs/${valueList[1]}/re100/supply_and_demand/monthly_reports/${YM}`;

    await API.get(dataMap.apiName, path, dataMap.header)
      .then((response) => {
        const initDateMap = getInitalDate(YM);
        const headerList = cloneData(initDateMap.header);
        headerList[0] = "";
        const supplyList = cloneData(initDateMap.list);
        supplyList[0] = "充電可能量(kWh)";
        const demandList = cloneData(initDateMap.list);
        demandList[0] = "使用電力量(kWh)";
        const diffList = cloneData(initDateMap.list);
        diffList[0] = "差分(kWh)";
        const reEnergyList = cloneData(initDateMap.list);
        reEnergyList[0] = "再エネ率(%)";

        const rowsList: Array<Array<string>> = [];
        response.forEach((resData: any) => {
          const num = resData.day_of_month;
          if (num >= 0 && supplyList[num]) {
            const supply: string = roundTwoDecimal(resData.supply).toFixed(1);
            const demand: string = roundTwoDecimal(resData.demand).toFixed(1);
            supplyList[num] = supply;
            demandList[num] = demand;
            diffList[num] =
              "" +
              roundTwoDecimal(parseFloat(supply) - parseFloat(demand)).toFixed(
                1
              );
            reEnergyList[num] =
              resData.demand == 0
                ? "－"
                : `${roundTwoDecimal(
                    Math.min(1, parseFloat(supply) / parseFloat(demand)) * 100
                  ).toFixed(1)}%`;
          }
        });

        rowsList.push(supplyList, demandList, diffList, reEnergyList);

        setRe100MonthlyData(() => {
          return (
            <>
              <TableContainer
                component={Paper}
                className="tableContents re100MonthlyContents"
              >
                <Table aria-label="simple table" className="re100MonthlyTable">
                  <TableHead>
                    <TableRow key={"re100Monthly_header"}>
                      {headerList.map((value: string, key: number) => {
                        return (
                          <TableCell
                            scope="row"
                            key={`re100Monthly_header_${key}`}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsList.map((row, index) => {
                      return (
                        <TableRow key={`re100Monthly_${index}`}>
                          {row.map((value: string, key: number) => {
                            return (
                              <TableCell
                                scope="row"
                                key={`re100Monthly_${index}_${key}`}
                                title={value}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          );
        });
      })
      .catch((err) => {
        setRe100MonthlyData(getErrMsg(err));
      });
  };

  useEffect(() => {
    selectThisMonth();
    updateRows();
  }, []);

  return (
    <>
      <Box component="div" className="tablePosition anchor" id="re100_3">
        <Box component="div" className="tableTitle">
          【月報:需給計画・実績】(一日単位)
        </Box>
        <Box
          component="div"
          className="datePickerArea"
          id="dwptReportDatePicker"
        >
          <Select native defaultValue="" id="re100MonthlySelect">
            {re100MonthlySelectDate}
          </Select>
          <Button
            variant="contained"
            color="primary"
            className="updateBtn"
            id="re100MonthlySelectBtn"
            onClick={updateRows}
          >
            表示
          </Button>
        </Box>
        <Box component="div">{re100MonthlyData}</Box>
      </Box>
    </>
  );
};

export default Re100Monthly;
